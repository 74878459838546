
























import { ConsumoRequest,ObtenerHaciendasDisponibles } from '@/entidades/KTB/Consumo/ConsumoEntity';
import TurnoAperturaResponse from '@/entidades/KTB/Turno/AperturaTurno';
import { Planta } from '@/entidades/Maestro/Planta';
import HaciendaItem from '@/entidades/Plantacion/HaciendaItem';
import Alerta from '@/entidades/Sistema/Alerta';
import Loading from '@/entidades/Sistema/Loading';
import {Vue,Component,Prop} from 'vue-property-decorator'
import { Validations } from 'vuelidate-property-decorators';
import { minValue, required } from 'vuelidate/lib/validators';
import {State,Action} from 'vuex-class';
@Component({
    name : 'ConsumoAdd'
})
export default class ConsumoAdd extends Vue
{
  //  @State('haciendas',{namespace:'maestroKtb'}) haciendas!:Array<HaciendaItem>;
    @State('usuarioId',{namespace:'authModule'}) usuarioId!:string;
    @State('plantaAsignada',{namespace: 'authModule'}) plantas!:Array<Planta>;
    @Prop({type:Object,required:false}) aperturaTurno!:TurnoAperturaResponse;
    @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function;
    @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function;

    haciendaId:number=0;
    haciendas:Array<HaciendaItem> = [];
    @Validations()
     validations = {
         haciendaId : {required,minValue:minValue(1)},
     }

    guardar()
    {
        var consumoRequest = new ConsumoRequest();
        consumoRequest.haciendaId = this.haciendaId;
        consumoRequest.aperturaTurnoId = this.aperturaTurno.aperturaTurnoId;

        this.$emit('guardarConsumo',consumoRequest);

    }
    async ObtenerHacienda()
    {
        try
        {
            this.changeLoading(new Loading(true,"Obteniendo hacienda disponibles..."));
            var rsp =  await ObtenerHaciendasDisponibles(this.plantas[0].plantaId);
            if(rsp.isSuccess)
            {
                this.haciendas = rsp.isResult;
            }
        }
        catch(error)
        {
            //@ts-ignore
            this.changeAlerta(new Alerta(error,true,"danger",3000));
        }
        finally
        {
            this.changeLoading(new Loading(false,"Obteniendo hacienda disponibles..."));
        }
    }

    mounted() 
    {
        this.ObtenerHacienda();    
    }

}
